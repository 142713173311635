.editor-view {
  margin-top: 20px;
  // margin-left: 10px;
  position: relative;
  height: 600px;
  width: 100%;
  background-color: #f7f7f7;
  left: 0;

  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}