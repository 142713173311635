.mainView {
    width: 100%;
    height: 100%;
}

#mainCanvas {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex: 1 1;
}

#mainScene {
    width: 100vw;
    height: 100vh;
}

#root {
    height: 100vh;
}
