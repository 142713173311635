#editorCanvas {
    // margin-top: 30px;
    width:100%;
    height: 50vh;
}

.listPaper {
    // height: 45%;
    overflow: hidden;
    margin: 20px;
    padding: 20px;
}

.listView {
    // max-height: 80%;
    overflow: auto;
}

.title {
    text-align: center;
    margin: 5%;
}

// .objectProperties {
//     margin: 5%;
//     padding: 5%;
// }

.playButton {
    margin: 10px;
}

.annotationButton {
    float: right;
}
