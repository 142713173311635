.mainView {
    width: 100% !important;
    height: 100% !important;

    background: black !important;
}


html, body {
    // background: black;
    width: 100%;
    height:100%;
    margin: 0px;
    padding: 0px;
}
canvas {
    width: 100%;
    height:100%;
}
