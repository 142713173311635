.login-page {
    width: 100%;
    padding: 8% 0 0;
    margin: auto;
  }

body {
  background: #f4f6f8;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;      
}
