body {
    background: lightblue;
}

.container {
    display: flex;
    flex-direction: column;
}

.box {
    padding: 20px;
    margin-bottom: 10px;
}

.gridBlock {
    border-style: solid;
    border-color: lightgray;
    background-color: azure;
    margin-bottom: 10px;
    justify-content: center;
    border-radius: 1ch;
}

.paper {
    padding: 20px;
    border-radius: 5px;
    background-color: white;
    margin-bottom: 10px;
    box-shadow: 0 1px 1px grey;
}

.paper img {
    max-width: 100%;
}

.usertestSelect {
    float: right;
}

.textBox {
    background-color: rgb(221, 236, 255);
}
